import { Component, Mixins } from 'vue-property-decorator';
import Layout from '@/components/layouts/main.vue';
import PageTitle from '@/components/ui/page-title/pageTitle.vue';
import { Action, Getter } from 'vuex-class';
import FilterMixin from '@/mixins/filter.mixin';
import BoxHeader from '@/components/ui/box-header/boxHeader.vue';
import { ReportsRankingTopKwsRequest } from '@/types/request/reports-request.types';
import { DateTime, Info } from 'luxon';
import { yearsInterval } from '@/helpers/date.helper';
import LineChart from '@/components/ui/charts/line-chart/lineChart.vue';
import { ChartData, ChartOptions } from 'chart.js';
import { COLOR_WHITE, COLORS } from '@/constants/color.constant';
import { processHEX } from '@/helpers/color.helper';
import { RankingTopKwsType } from '@/types/api/reports/ranking-top-kws.type';

const title = 'rankingTopKwsReport.title';

@Component({
  name: 'RankingTopKwsReport',
  page: {
    title
  },
  components: {
    Layout,
    PageTitle,
    BoxHeader,
    LineChart: LineChart as any,
  },
})
export default class RankingTopKwsReport extends Mixins(FilterMixin) {
  title = title;
  filter: any = this.initFilter();

  @Action('getRankingTopKwsReport', { namespace: 'reports' })
  getRankingTopKwsReport!: (params?: ReportsRankingTopKwsRequest) => Promise<RankingTopKwsType[]>;

  @Getter('getRankingTopKwsReport', { namespace: 'reports' })
  rankingTopKwsReport!: RankingTopKwsType[];

  created() {
    this.getRankingTopKwsReport();
  }

  initFilter() {
    return {
      product: this.$route.query.product || null,
      month: this.$route.query.month || null,
      year: this.$route.query.year || null,
    };
  }

  get monthsOptions() {
    return [
      { text: this.$t('rankingTopKwsReport.month'), value: null },
      ...Info.months('long').map(item => ({ text: item, value: item }))
    ];
  }

  get yearsOptions() {
    return [
      { text: this.$t('rankingTopKwsReport.year'), value: null },
      ...yearsInterval(
        DateTime.local().minus({ years: 2 }),
        DateTime.local()
      ).map(item => ({ text: item, value: item }))
    ];
  }

  get chartData(): ChartData {
    return {
      labels: this.rankingTopKwsReport[0].data.map(item => item.date),
      datasets: this.rankingTopKwsReport.map((item, index) => ({
        label: item.brand,
        data: item.data.map(dItem => dItem.count),
        borderColor: COLORS[index],
        backgroundColor: `rgba(${processHEX(COLORS[index]).join(',')}, 0.3)`,
        pointBackgroundColor: COLOR_WHITE,
        pointBorderColor: COLORS[index],
        pointBorderWidth: 2,
      }))
    };
  }

  get chartData2(): ChartData {
    return {
      labels: this.rankingTopKwsReport[0].data.map(item => item.date),
      datasets: this.rankingTopKwsReport.map((item, index) => ({
        label: item.brand,
        data: item.data.map(dItem => dItem.count).reverse(),
        borderColor: COLORS[index],
        backgroundColor: `rgba(${processHEX(COLORS[index]).join(',')}, 0.3)`,
        pointBackgroundColor: COLOR_WHITE,
        pointBorderColor: COLORS[index],
        pointBorderWidth: 2,
      }))
    };
  }

  get chartOptions(): ChartOptions {
    return {
      layout: {
        padding: {
          left: 50,
          right: 50,
          top: 25,
          bottom: 25,
        }
      },
      plugins: {
        tooltip: {
          enabled: false
        },
      },
      animation: {
        onProgress: ({ chart }) => {
          const ctx = chart.ctx;
          ctx.textAlign = 'center';
          ctx.fillStyle = "rgba(0, 0, 0, 1)";
          ctx.textBaseline = 'bottom';

          this.chartData.datasets?.forEach((item, i) => {
            const meta = chart.getDatasetMeta(i);
            if (!meta.hidden) {
              meta.data.forEach((bar, index) => {
                ctx.fillText(
                  item.data?.[index] ? item.data[index]!.toString() : '',
                  bar.x,
                  bar.y - 5
                );
              });
            }
          })
        }
      },
    }
  }
}
